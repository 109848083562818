import React, { useState } from 'react'
import SubHeader from '../customComponents/SubHeader'
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../redux/alertSlice';
import api from '../../utils/axiosInstance';
import SchemeStatementHistory from './Scheme/SchemeStatementHistory';

const SchemeStatement = () => {

  const [schemeid, setSchemeid] = useState('')
  const [formData, setFormData] = useState({
    name:'',
    mobileno:'',
  })
  const [statement, setStatement] = useState([])
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch();

  const handleGenerateStatement = async ()=>{
    try {
      setLoading(true)
      const response = await api.post('scheme/generate-statement', {schemeid:schemeid})
      if (response.data?.success) {
      setStatement(response.data?.data)
      setFormData((prev)=>({...prev, name:response.data?.custname, mobileno:response.data?.mobileno}))
      setLoading(false)
      } else {
      setLoading(false)
      dispatch(showAlert({type:'warning', message:response.data?.message}))
      }
    } catch (error) {
      setLoading(false)
      console.log('Error in generating statement.', error)
      dispatch(showAlert({type:'error', message:'Error in generating statement.'}))
    }
  }

  const handleGenerate = async() =>{
    if (schemeid !== '') {
      handleGenerateStatement()
    } else {
      dispatch(showAlert({type:'warning', message:'Scheme id cannot be blank.'}))
    }
  } 

  return (
    <>
      <SubHeader text={"Scheme Statement"} />
    <div className="w-[1100px] mx-auto">
      <div className="relative w-full lg:min-h-[550px] rounded-[10px] mt-8 mb-2 shadow-custom-dark">
          <div className="w-full py-6 px-8">
            <div>
              <div className="font-mont font-semibold border-b pb-2">
                Scheme Statement 
              </div>
              <div className="flex px-2 justify-between items-end">
                <div className="mt-6 bg-white">
                <TextField
                  value={schemeid}
                  label="Enter Scheme ID"
                  size='small'
                  onChange={(e)=>{setSchemeid(e.target.value)}}
                />
                </div>
                <div>
                <button
                  className="py-2 px-10 border bg-sixth text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-sixth/80"
                  disabled={loading}
                  onClick={handleGenerate}
                >
                  Generate Statement
                </button>
                </div>

                <div className="mt-6 bg-white">
                <TextField
                  value={formData.name}
                  label="Name"
                  variant="standard"
                />
                </div>

                <div className="mt-6 bg-white">
                <TextField
                  value={formData.mobileno}
                  label="Mobile No."
                  variant="standard"
                />
                </div>
              </div>
            </div>
          </div>
 
       <div className='w-full px-7 pb-6'>
         <SchemeStatementHistory data={statement}/>
       </div>

      </div>
    </div>
    </>
  )
}

export default SchemeStatement
