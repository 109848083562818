import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PhonelinkSetupOutlinedIcon from '@mui/icons-material/PhonelinkSetupOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const menuItems = [
  { id: 0, link: "/home", icon: <DashboardIcon />, label: "Dashboard" },
  { id: 1, link: "/home/mobile-app", icon: <PhonelinkSetupOutlinedIcon />, label: "Mobile Integration" },
  { id: 2, link: "/home/customer-profile", icon: <BadgeOutlinedIcon />, label: "Customer Profile" },
  { id: 3, link: "/home/loyalty-points", icon: <AutoAwesomeOutlinedIcon />, label: "Loyalty Points" },
  { id: 4, link: "/home/scheme-generation", icon: <LocalOfferOutlinedIcon />, label: "Scheme Initiation" },
  { id: 5, link: "/home/user", icon: <ManageAccountsOutlinedIcon />, label: "My Profile" },
];

const menuItems2 = [
  { id: 6, link: "/home/statement", icon: <LibraryBooksOutlinedIcon />, label: "Points Statement" },
  { id: 7, link: "/home/scheme-statement", icon: <LibraryBooksOutlinedIcon />, label: "Scheme Statement" },
  { id: 8, link: "/home/scheme-summary", icon: <AssessmentOutlinedIcon />, label: "Scheme Summary" },
]

const SideMenu = () => {
  const [selectedId, setSelectedId] = useState(0);
  const navigate = useNavigate();

  return (
    <div className="relative w-full h-full font-mont px-2 bg-primary text-white shadow-custom-medium">
      <div className="font-semibold px-3 pt-4 text-[22px]">Jaina Jewellers</div>

      <div className="mt-8 font-mont font-semibold">
        {menuItems.map(({ id, link, icon, label }) => (
          <div
            key={id}
            className="mb-2"
            onClick={() => setSelectedId(id)}
          >
            <Link to={link}>
              <div
                className={`flex items-center px-3 py-2 text-sm rounded-[10px] cursor-pointer hover:bg-second/30 transition-all duration-200 ${
                  selectedId === id ? "bg-second text-primary" : "text-fourth"
                }`}
              >
                <div className="mr-3">{icon}</div>
                <p>{label}</p>
              </div>
            </Link>
          </div>
        ))}

        <div className="border-b my-4 border-gray-600"></div>

       {menuItems2.map(({ id, link, icon, label }) => (
          <div
            key={id}
            className="mb-2"
            onClick={() => setSelectedId(id)}
          >
            <Link to={link}>
              <div
                className={`flex items-center px-3 py-2 text-sm rounded-[10px] cursor-pointer hover:bg-second/30 transition-all duration-200 ${
                  selectedId === id ? "bg-second text-primary" : "text-fourth"
                }`}
              >
                <div className="mr-3">{icon}</div>
                <p>{label}</p>
              </div>
            </Link>
          </div>
          ))}

        <div
          onClick={() => {
            localStorage.removeItem("accessToken");
            navigate("/");
          }}
          className="absolute flex bottom-8 z-50 items-center px-3 py-2 text-sm rounded-[10px] cursor-pointer hover:bg-second/30 transition-all duration-200"
        >
          <div className="mr-3">
            <LogoutOutlinedIcon />
          </div>
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
