import moment from 'moment'
import React, {useState } from 'react'
import { TrashIcon } from '@heroicons/react/24/solid'
import ModalDelete from './ModalDelete'

const SchemeHistory = ({schemes, refresh}) => {

    const [modalBoxStatus, setModalBoxStatus] = useState(false)
    const [schemeId, setSchemeId] = useState(null)

  return (
    <div className="border h-full rounded-[15px] shadow-custom-light overflow-hidden">
      {/* Header */}
      <ModalDelete status={modalBoxStatus} setStatus={(value)=>{setModalBoxStatus(value)}} schemeId={schemeId} refresh={refresh}/>

      <div
        className={`bg-sixth flex text-white font-mont font-semibold text-sm`}
      >
        <div className="w-[20%] py-2 px-3">Scheme ID</div>
        <div className="w-[20%] py-2 px-3">Start Date</div>
        <div className="w-[20%] py-2 px-3">Maturity Date</div>
        <div className="w-[20%] py-2 px-3">Amount</div>
        <div className="w-[20%] py-2 px-3">Status</div>
      </div>
      <div className="h-[240px] overflow-y-auto font-mont font-bold text-sm">
        {schemes.length > 0 ? (
          schemes.map((row, i) => (
            <div
              key={row?.schemeid}
              className={`flex font-semibold text-sm hover:bg-sixth/30 ${
                i % 2 === 0 ? "bg-sixth/10" : "bg-sixth/5"
              }`}
            > 
              <div className="w-[20%] h-[35px] flex items-center px-3">
                {row?.schemeid}
              </div>
              <div className="w-[20%] h-[35px] flex items-center px-3">
                {moment(row?.startdt).format('DD-MM-YYYY')}
              </div>
              <div className="w-[20%] h-[35px] flex items-center px-3">
               {row?.matdt !== null || row?.matdt === '' ? moment(row?.matdt).format('DD-MM-YYYY') : moment(row?.startdt).add(364, 'days').format('DD-MM-YYYY')}
              </div>
              <div className="w-[20%] h-[35px] flex items-center px-3">
                {row?.amount}
              </div>
              <div className="w-[20%] h-[35px] flex items-center px-3">
                {
                (row?.matyn === 'n' || row?.matyn === '' || !row?.matyn) && 
                <>
                 <button 
                 onClick={()=>{setModalBoxStatus(true); setSchemeId(row?.schemeid)}}
                 className='flex items-center space-x-1 border rounded-lg px-2 py-[2px] border-red-500 bg-white'>
                 <div >Inactive</div>
                 <TrashIcon className='w-4 h-4 text-red-500'/>
                 </button>
                </>
                }
                {row?.matyn === 'y' && <button className='border rounded-lg px-2 py-[2px] border-green-500 bg-white'>Active</button>}
                {row?.matyn === 'm' && <button className='border rounded-lg px-2 py-[2px] border-third bg-white'>Completed</button>}
              </div>
            </div>
          ))
        ) : (
          <div className="">
            <img className="w-64 mx-auto" src="/table/tablebg.jpg" alt="" />
            <div className="text-center font-mont font-sm font-semibold text-gray-400">
            Currently, there are no active schemes
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SchemeHistory
