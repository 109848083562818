import React, { useEffect, useRef, useState } from "react";
import SubHeader from "../customComponents/SubHeader";
import { Autocomplete, TextField } from "@mui/material";
import api from "../../utils/axiosInstance";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/alertSlice";

const CustomerProfile = () => {
  
  const [formData, setFormData] = useState({
    custid:'',
    mobileno : '',
    custname: '',
    email: '',
    address: '',
    city:'',
    pincode:'',
    state:'',
    dob: moment(new Date()).format('YYYY-MM-DD'),
    doa: moment(new Date()).format('YYYY-MM-DD'),
    aadhaar: '',
    pan:''
  }) 
  console.log(formData)
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]); // option array coming from searching
  const [loading, setLoading] = useState(false);

  const searchBoxRef = useRef();
  const dispatch = useDispatch()

  const getCustomerList = async (value) => {
    try {
      const response = await api.get(
        `/customers/get-all-customers?search=${value}`
      );
      if (response.status === 200) {
        const list = response.data;
        //console.log(list);
        setOptions(list);
      }
    } catch (error) {
      console.log("Error while loading customers " + error);
    }
  };

  const getCustomerData = async (number) => {
   try {
      setLoading(true)
      const response = await api.post('/customers/get-customer-data', {mobileno : number});
      if (response.data?.success) {
        const {custid, mobileno, custname, email, address, city, pincode, state, dob, doa, aadhaar, pan} = response.data?.data
        setFormData((prev)=>({
          ...prev,
          custid: custid,
          mobileno : mobileno,
          custname : custname,
          email : email,
          address : address,
          city : city,
          pincode : pincode,
          state : state,
          dob : moment(dob).format('YYYY-MM-DD'),
          doa : moment(doa).format('YYYY-MM-DD'),
          aadhaar : aadhaar,
          pan : pan
        }))
        setLoading(false)
      } else {
        console.log(response.data?.message)
        setLoading(false)
      }
   } catch (error) {
    console.log(error)
    setLoading(false)
   }
  }
  
  const handleOnChange = async (value)=>{  // Get info of cutomer for selected option from list
   getCustomerData(value?.MOBILENO)
  }

  const handleSearch = (e)=>{  // Get searched customer list 
     const {value} = e.target
     if (value && value !== '') {
         setFormData((pre)=>({
          ...pre,
          mobileno: value
        }))
         getCustomerList(value)
     }
  }

  const handleInputChange = (name, value)=>{
    setFormData((pre)=>({
      ...pre,
      [name]:value
    }))
  }

  const handleSave = async ()=>{
    try {
      setLoading(true)
      const response =  await api.post('/customers/update-customer-data',{formData})
      if (response.data?.success) {
        console.log(response.data?.message)
        dispatch(showAlert({type:'success',message:response.data?.message}))
        setLoading(false)
        handleCancel()
      } else {
        console.log(response.data?.message)
        dispatch(showAlert({type:'warning',message:response.data?.message}))
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      dispatch(showAlert({type:'error',message:'Error in saving.'}))
      setLoading(false)
    }
  }

  const handleOnSaveClick = async ()=>{
    if (formData?.mobileno !== '' && formData.mobileno.length === 10) {
      handleSave();
    } else {
      if (formData.mobileno === '' || inputValue === '') {
        dispatch(showAlert({type:'warning', message:'Please enter 10 digits mobile no before proceeding.'}))
      } else if(formData.mobileno.length !== 10) {
        dispatch(showAlert({type:'warning', message:'Mobile number should be of 10 digits.'}))
      } else if (formData.custname === '') {
        dispatch(showAlert({type:'warning', message:'Please enter name before proceeding.'}))
      }
    }
  }

  const handleCancel = () => {
    setFormData({
      custid: '',
      mobileno: '',
      custname: '',
      email: '',
      address: '',
      city: '',
      pincode: '',
      state: '',
      dob: moment(new Date()).format('YYYY-MM-DD'),
      doa: moment(new Date()).format('YYYY-MM-DD'),
      aadhaar: '',
      pan: '',
    });
    setSelectedValue(null); // Clear selected value in Autocomplete
    setInputValue(''); // Clear input text
  };

  useEffect(()=>{
    searchBoxRef.current.focus();
  },[])

  return (
    <div>
      <SubHeader text={"Customer Profile"} />
      <div className="w-full lg:w-[1100px] mx-auto">
        <div className="w-full mx-auto rounded-[10px] mt-16  shadow-custom-dark">
          <div className="w-full flex">
            <div className="hidden lg:flex lg:w-[35%] items-center bg-white rounded-l-[10px]">
              <img
                className="w-full mx-auto rounded-l-[10px]"
                src="/forms/customer_profile.png"
                alt=""
              />
            </div>
            <div className="w-full lg:w-[65%] lg:py-8 lg:px-10">
              <div className="lg:flex justify-between items-end border-b pb-2">
                <div className="font-mont font-semibold">
                  Create / Update customer profile
                </div>
              </div>

              <div className="w-full">
                <div className="w-full">
                  <div className="mt-7">
                    <Autocomplete
                      size="small"
                      freeSolo
                      autoHighlight
                      value={selectedValue}
                      inputValue={inputValue} // Bind the inputValue to control the input text
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue); // Update the input text
                        handleSearch({ target: { value: newInputValue } });
                      }}
                      onChange={(event, newValue) => {
                        setSelectedValue(newValue); // Update selected object
                        newValue && handleOnChange(newValue); // Trigger onChange action
                      }}
                      options={options} // Pass the original customer objects
                      getOptionLabel={(option) =>
                        `${option.CUSTNAME}   (+91 ${option.MOBILENO})`
                      } // Control how options are displayed
                      renderInput={(params) => (
                        <TextField
                        {...params}
                          autoComplete="newpassword"
                          label='Enter mob no.'
                          inputRef={searchBoxRef} // Use inputRef to focus the input field
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.custname}
                      label="Name"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('custname',e.target.value)}}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.email}
                      label="Email"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('email',e.target.value)}}
                    />
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.address}
                      label="Address"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('address',e.target.value)}}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.city}
                      label="City"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('city',e.target.value)}}
                    />
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.pincode}
                      label="Pincode"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('pincode',e.target.value)}}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.state}
                      label="State"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('state',e.target.value)}}
                    />
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      type="date"
                      value={formData.dob}
                      label="Date of Birth"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('dob',e.target.value)}}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      type="date"
                      value={formData.doa}
                      label="Anniversary Date"
                      variant="standard"
                      onChange={(e)=>{handleInputChange('doa',e.target.value)}}
                    />
                  </div>
                </div>

                <div className="lg:flex space-x-6 justify-between">
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.aadhaar}
                      label="Aadhaar"
                      variant="standard"
                      inputProps={{ maxLength: 16 }} // Set max length here
                      onChange={(e)=>{handleInputChange('aadhaar',e.target.value)}}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      className="w-full"
                      autoComplete="newpassword"
                      value={formData.pan}
                      label="PAN"
                      variant="standard"
                      inputProps={{ maxLength: 10 }} // Set max length here
                      onChange={(e)=>{handleInputChange('pan',e.target.value)}}
                    />
                  </div>
                </div>
              </div>

              <div className="flex mt-6">
                <button
                  onClick={handleOnSaveClick}
                  className="py-2 w-28 mr-2 border bg-third text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-third/70"
                  disabled={loading}
                >
                  SAVE
                </button>
                <button
                  onClick={handleCancel}
                  className="py-2 w-28 border border-fifth  text-fifth text-sm font-mont font-semibold rounded-[5px] hover:bg-white"
                  disabled={loading}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
