import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "../../utils/axiosInstance";

const FreeSolo = ({ value, getValue, searchLabel }) => {
  const [options, setOptions] = useState([]);
  const searchBoxRef = useRef();

  const getCustomerList = async (inputValue) => {
    try {
      const response = await api.get(
        `/customers/get-all-customers?search=${inputValue}`
      );
      if (response.status === 200) {
        setOptions(response.data);
      }
    } catch (error) {
      console.log("Error while loading customers " + error);
    }
  };

  const handleOnChange = (newValue) => {
    getValue(newValue);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    value && getCustomerList(value);
  };

  useEffect(() => {
    searchBoxRef.current.focus();
  }, []);

  return (
    <Autocomplete
      size="small"
      freeSolo
      autoHighlight
      onChange={(event, newValue) => {
        handleOnChange(newValue);
      }}
      onInputChange={handleSearch}
      options={options}
      getOptionLabel={(option) => `${option.CUSTNAME}   (+91 ${option.MOBILENO})`}
      renderInput={(params) => (
        <TextField
          {...params}
          value={value || ''} // Bind the value prop here
          label={searchLabel}
          inputRef={searchBoxRef}
        />
      )}
    />
  );
};


export default FreeSolo;
