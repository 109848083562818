import React, { useState } from 'react'
import SubHeader from '../customComponents/SubHeader'
import { TextField } from '@mui/material';
import CustomerSearchBox from '../customComponents/CustomerSearchBox'
import moment from 'moment';
import SchemeHistory from './Scheme/SchemeHistory';
import ModalBox from './Scheme/ModalBox'
import { useDispatch } from 'react-redux';
import { showAlert } from '../../redux/alertSlice';
import api from '../../utils/axiosInstance';

const SchemeGeneration = () => {

  const [formData, setFormData] = useState({
    custid: '',
    mobileno: '',
    custname: '',
    startdt : moment(new Date()).format('YYYY-MM-DD'),
    amount : ''
  })
  const [schemes, setSchemes] = useState([])
  const [modalBoxStatus, setModalBoxStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const dispatch = useDispatch();

  const getCustomerSchemes = async (value)=>{
    try {
      const response = await api.post('/scheme/get-all', {custid : value})
      if (response.data?.success) {
        setSchemes(response.data?.data)
      } else {
        setSchemes([])
      }
    } catch (error) {
      console.log('Error in getting customer previous schemes. ',error)
      dispatch(showAlert({type:'error', message:'Error in getting customer previous schemes.'}))
    }
  }

  const handleCustomerSelect = (value) =>{
    setFormData({
      custid: '',
      mobileno: '',
      custname: '',
      startdt : moment(new Date()).format('YYYY-MM-DD'),
      amount : ''
    })
    setSchemes([])
    setFormData((prev)=>({
      ...prev,
      custid: value?.custid,
      mobileno: value?.MOBILENO,
      custname: value?.CUSTNAME,
    }));
    getCustomerSchemes(value.custid)
  }

  const handleInputChange = (name, value)=>{
    setFormData((prev)=>({
      ...prev,
      [name] : value
    }))
  }

  const handleRefresh = ()=>{
    getCustomerSchemes(formData?.custid)
    setFormData((pre)=>({
      ...pre,
      amount:''
    }))
  }

  const handleGenerateScheme = async ()=>{
    try {
      setLoading(true);
      const response = await api.post('/scheme/generate',formData)
      if (response.data?.success) {
        dispatch(showAlert({type:'success', message:response.data?.message}))
        handleRefresh()
        setLoading(false);
      } else {
        dispatch(showAlert({type:'error', message:response.data?.message}))
        setLoading(false)
      }
    } catch (error) {
      console.log('Error in generating scheme. ',error)
      dispatch(showAlert({type:'error', message:'Error in generating scheme.'}))
      setLoading(false)
    }
  }

  const checkPAN = async () =>{
    try {
      const response = await api.post('scheme/verify-pan', {custid : formData.custid})
      if (response.data?.success) {
        const pan = response.data?.data?.pan
        if (pan && pan.length === 10 && pan !== '') {
          handleGenerateScheme();
        } else {
          setModalBoxStatus(true)
        }
      } else {
        setModalBoxStatus(true)
      }
    } catch (error) {
      console.log('Error in generating scheme. ',error)
      dispatch(showAlert({type:'error', message:'Error in verifying PAN.'}))
    }
  }

  const handleSave = ()=>{
    if (formData.custid !== '' && formData.amount !== '' && formData.amount <= 16000 && schemes[schemes.length-1]?.matyn !== 'n') {
      handleGenerateScheme();
    } else {
      if (formData.custid === '') {
        dispatch(showAlert({type:'warning', message:'Please select customer before proceeding.'}))
      } else if (formData.amount === '') {
        dispatch(showAlert({type:'warning', message:'Please enter amount before proceeding.'}))
      } else if (schemes[schemes.length-1]?.matyn === 'n') {
        dispatch(showAlert({type:'warning', message:'The previous scheme installment must be completed before initiating a new scheme.'}))
      } else if (formData.amount >= 16000) {
        checkPAN();
      }
    }
  }

  return (
    <>
      <SubHeader text={"Scheme Initiation"} />
    <div className="w-[1100px] mx-auto">
      <ModalBox status={modalBoxStatus} setStatus={(value)=>{setModalBoxStatus(value)}} custid={formData?.custid} save={handleSave}/>
      <div className="relative w-full lg:min-h-[450px] rounded-[10px] mt-8 mb-2 shadow-custom-dark">
        <div className="flex">
          <div className="w-[40%] flex justify-center">
            <img
              className="w-[340px] object-cover"
              src="/forms/formbg2.jpg"
              alt=""
            />
          </div>

          <div className="w-[60%] py-6 px-6">
            <div>
              <div className="font-mont font-semibold border-b pb-2">
                Generate Scheme
              </div>
              <div className="flex space-x-12 items-end">
                <div className="w-[350px] mt-6 bg-white">
                  <CustomerSearchBox
                    searchLabel={'Search customer'}
                    getValue={(value) => {handleCustomerSelect(value)}}
                  />
                </div>
                <div>
                  <TextField
                    sx={{ width: 210 }}
                    value={formData.custname}
                    label="Name"
                    variant="standard"
                    slotProps={{ input: { readOnly: true } }} // New way to apply readOnly
                  />
                </div>
              </div>

              <div className="mt-6 flex items-end space-x-12">
                <TextField
                  sx={{ width: 150 }}
                  value={formData.startdt}
                  label="Date"
                  variant="standard"
                  onChange={(e)=>{handleInputChange('startdt',e.target.value)}}
                  type="date"
                />
                <TextField
                  sx={{ width: 150 }}
                  value={formData.amount}
                  label="Amount"
                  variant="standard"
                  onChange={(e) => {
                    if (e.target.value.length <= 7) {
                      handleInputChange('amount', e.target.value);
                    }
                  }}
                />
                <button
                  className="py-2 px-10 border bg-sixth text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-sixth/80"
                  disabled={loading}
                  onClick={handleSave}
                >
                  Generate Scheme
                </button>
              </div>
            </div>
          </div>
        </div>
 
       <div className='w-full px-7 pb-6'>
         <SchemeHistory schemes={schemes} refresh={handleRefresh}/>
       </div>

      </div>
    </div>
    </>
  )
}

export default SchemeGeneration
